var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cart-item"},[_c('v-list-item',{class:_vm.$vuetify.breakpoint.xs ? 'py-3 is-checkout' : 'py-2 is-not-checkout',attrs:{"to":_vm.product.existsInShop
        ? { name: 'Product', params: { slug: _vm.product.slug } }
        : null}},[_c('v-row',{staticClass:"my-2 my-sm-0"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex"},[_c('v-list-item-avatar',{staticClass:"my-0",attrs:{"width":"70","height":"70","rouned":"0"}},[(_vm.product.existsInShop)?_c('img',{attrs:{"src":_vm.product.mediaURL,"alt":_vm.product.name,"onerror":"this.onerror=null;this.src='/no-icon.png'"}}):_vm._e()]),_c('v-list-item-content',[_c('div',{staticClass:"text-body-2 wp-ingredient"},[_vm._v(" "+_vm._s(_vm.product.wp_amount)+" "+_vm._s(_vm.product.wp_unit)+" "+_vm._s(_vm.product.wp_ingredient)+" ")])])],1)]),_c('v-col',{staticClass:"pl-sm-0",attrs:{"cols":"12","md":"6"}},[_c('v-list-item-action',{staticClass:"d-flex flex-row align-center justify-space-between justify-lg-end mr-0"},[(_vm.product.available > 0)?[(_vm.product.price)?_c('ProductPrice',{staticClass:"text-body-1",attrs:{"product":_vm.product,"isList":true}}):_vm._e(),_c('div',{staticClass:"product-qty justify-end w-100"},[_c('div',{staticClass:"qty-wrap rounded-sm",class:{
                  'not-empty': _vm.quantity > 0,
                  'show-add-btn': _vm.quantity < 1
                },on:{"mousedown":function($event){$event.stopPropagation();}}},[_c('v-btn',{staticClass:"minus",attrs:{"aria-label":"Diminuisci quantità","depressed":"","color":"grey lighten-1","small":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.minus(_vm.props.updateCart, {
                      infos: { ..._vm.props.selectedOptions }
                    })}}},[_c('v-icon',[_vm._v("$minus")])],1),_c('div',{staticClass:"val-cnt"},[_c('span',{staticClass:"val"},[_vm._v(_vm._s(_vm.quantity)+_vm._s(_vm.unit))]),_c('span',{staticClass:"small"},[_vm._v(_vm._s(_vm.packageCount))])]),_c('v-btn',{staticClass:"plus grey",attrs:{"aria-label":"Aumenta quantità","color":"grey lighten-1","small":"","depressed":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.plus(_vm.props.updateCart, {
                      infos: {
                        ..._vm.props.selectedOptions
                      }
                    })}}},[_c('v-icon',[_vm._v("$plus")])],1)],1),_c('v-btn',{staticClass:"add-to-cart-btn",attrs:{"depressed":"","height":"50","min-width":"50","color":"primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.addToCart(_vm.quantity, { infos: { ..._vm.selectedOptions } })}}},[_c('v-icon',[_vm._v("$cart")])],1)],1)]:_c('div',{staticClass:"not-available ml-auto"},[_vm._v(" Il prodotto non è disponibile ")])],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }