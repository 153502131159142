<template>
  <div class="cart-item">
    <v-list-item
      :to="
        product.existsInShop
          ? { name: 'Product', params: { slug: product.slug } }
          : null
      "
      :class="
        $vuetify.breakpoint.xs ? 'py-3 is-checkout' : 'py-2 is-not-checkout'
      "
    >
      <v-row class="my-2 my-sm-0">
        <v-col cols="12" md="6">
          <div class="d-flex">
            <v-list-item-avatar width="70" height="70" rouned="0" class="my-0">
              <img
                v-if="product.existsInShop"
                :src="product.mediaURL"
                :alt="product.name"
                onerror="this.onerror=null;this.src='/no-icon.png'"
              />
            </v-list-item-avatar>

            <v-list-item-content>
              <div class="text-body-2 wp-ingredient">
                {{ product.wp_amount }} {{ product.wp_unit }}
                {{ product.wp_ingredient }}
              </div>
            </v-list-item-content>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="pl-sm-0">
          <v-list-item-action
            class="d-flex flex-row align-center justify-space-between justify-lg-end mr-0"
          >
            <template v-if="product.available > 0">
              <ProductPrice
                v-if="product.price"
                :product="product"
                :isList="true"
                class="text-body-1 "
              ></ProductPrice>
              <div class="product-qty justify-end w-100">
                <div
                  class="qty-wrap rounded-sm"
                  :class="{
                    'not-empty': quantity > 0,
                    'show-add-btn': quantity < 1
                  }"
                  @mousedown.stop
                >
                  <v-btn
                    aria-label="Diminuisci quantità"
                    depressed
                    color="grey lighten-1"
                    small
                    class="minus"
                    @click.stop.prevent="
                      minus(props.updateCart, {
                        infos: { ...props.selectedOptions }
                      })
                    "
                  >
                    <v-icon>$minus</v-icon>
                  </v-btn>
                  <div class="val-cnt">
                    <span class="val">{{ quantity }}{{ unit }}</span>
                    <span class="small">{{ packageCount }}</span>
                  </div>
                  <v-btn
                    aria-label="Aumenta quantità"
                    class="plus grey"
                    color="grey lighten-1"
                    small
                    depressed
                    @click.stop.prevent="
                      plus(props.updateCart, {
                        infos: {
                          ...props.selectedOptions
                        }
                      })
                    "
                  >
                    <v-icon>$plus</v-icon>
                  </v-btn>
                </div>

                <v-btn
                  depressed
                  class="add-to-cart-btn"
                  height="50"
                  min-width="50"
                  color="primary"
                  @click.stop.prevent="
                    addToCart(quantity, { infos: { ...selectedOptions } })
                  "
                >
                  <v-icon>$cart</v-icon>
                </v-btn>
              </div>
            </template>

            <div v-else class="not-available ml-auto">
              Il prodotto non è disponibile
            </div>
          </v-list-item-action>
        </v-col>
      </v-row>
    </v-list-item>
  </div>
</template>
<style lang="scss" scoped>
@media #{map-get($display-breakpoints, 'lg-only')} {
  .wp-ingredient {
    max-width: 150px;
  }
}
.product-qty {
  width: 100%;
  max-width: 100%;
}
.product-price {
  min-width: unset;
  .price {
    font-size: 18px;
  }
}
.qty-wrap {
  width: 100%;
  min-width: 130px;
  max-width: 100%;
  .val-cnt .val {
    color: $text-color;
  }
  .minus,
  .plus {
    .v-icon {
      color: $text-color;
    }
  }
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .qty-wrap {
    max-width: 200px;
  }
}

.header {
  height: 45px;
  width: 100%;
  .icons-box {
    display: flex;
    margin-left: 3px;
  }
}
.not-available {
  color: var(--v-error-base);
  font-size: 12px;
  white-space: normal;
  height: 40px;
  display: flex;
  align-items: flex-end;
  text-align: right;
}
.product-card {
  width: 100%;
}
.ingredient-container,
.ingredient-col {
  max-height: 50px;
  margin-bottom: 16px !important;
}
.name {
  // font-size: 1rem !important;
}
.cur-price {
  // font-size: 18px !important;
  // max-width: fit-content;
}
.product-img {
  max-width: 64px;
}
.actions {
  // justify-content: flex-end !important;
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .ingredient-container {
    height: auto;
    margin-bottom: 16px !important;
  }
  .product-img {
    max-width: 42px;
  }
  .name {
    font-size: 11px !important;
  }
  .qty-wrap div {
    font-size: 11px !important;
    padding: 0 4px !important;
  }
  .qty-wrap i {
    //  font-size: 18px !important;
  }
}
</style>
<script>
import { mapState, mapGetters } from "vuex";
import productMixin from "~/mixins/product";
import ProductPrice from "@/components/product/ProductPrice.vue";

export default {
  name: "ProductCard",
  props: ["product"],

  mixins: [productMixin],
  components: { ProductPrice },
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId",
      isAuthenticatedss: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    productClasses() {
      if (global.config.filterProductClasses && this.product.productClasses) {
        return this.product.productClasses.filter(
          p =>
            global.config.filterProductClasses.includes(p.productClassId) &&
            p.productClassTypeId == 2 &&
            p.productClassGroupId != 1
        );
      } else {
        return (
          this.product?.productClasses &&
          this.product?.productClasses.filter(
            p => p.productClassTypeId == 2 && p.productClassGroupId != 1
          )
        );
      }
    },
    item() {
      return null;
    }
  }
};
</script>
